import { apiRequire_token } from '@/resource'
export default {
  data() {
    return {
      token: '',
    }
  },
  mounted() {
    this.fetchToken()
  },
  methods: {
    fetchToken() {
      apiRequire_token()
        .then(resp => {
          if (resp.data.code == 0) this.token = resp.data.data
        })
        .catch(err => {
          this.errorMessage('Fetch token failed.')
        })
    },
    errorMessage(message) {
      return this.$message({
        message: message,
        type: 'error',
      })
    },
  },
}
